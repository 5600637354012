import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Progress, Spacer, Text, useToast, VStack } from "@chakra-ui/react";
import { PDFIcon } from "../components/Icons";
import { MdSave, MdUpload } from "react-icons/md";
import { useRef, useState } from "react";
import { createFileDataSource } from "../services/reports";
import { useNavigate } from "react-router";
//const BACKEND = process.env['REACT_APP_BACKEND_URL'] as string


let file: File | null = null;

export function CreatePDFDataSource() {

    const [filename, setFilename] = useState<string>('-- upload a file --')
    const [dataSourceName, setDataSourceName] = useState<string>('')
    const [isProcessing, setIsProcessing] = useState<boolean>(false)

    const toast = useToast();
    const navigate = useNavigate()
    

    return <Flex direction={'column'}
        alignItems={'center'}
        paddingTop={'50px'}

    >
        <HStack width={'400px'}><PDFIcon size="40px" />
            <Text>Add PDF Source</Text>
            <Spacer />
        </HStack>   

        <Box width={'400px'} marginTop={'30px'}>
            <FormControl>
                <FormLabel>Name</FormLabel>
                <Input onChange={(e) => setDataSourceName(e.target.value)} />
            </FormControl>

            <HStack width={'400px'} marginTop={'30px'}>

                <Text>{filename}</Text>
               
                <Spacer />
                <Button
                    backgroundColor='#a080ff'
                   
                    color={'white'}
                    onClick={() => { document.getElementById('fileUploadForm')?.click(); }}

                > <HStack><MdUpload/><Text>Choose File</Text></HStack> </Button>
            </HStack>

            <Input style={{ display: "none" }} id="fileUploadForm" type="file" multiple={true}
        accept="file/pdf"
        onChange={ (e) => {
            const uploadedFile: File = e.target!.files![0];
            setFilename(uploadedFile.name)
            file = uploadedFile;      
        
        }}

      />
          {
            isProcessing? <VStack>
                <Progress width={'100%'} marginTop={'10px'} size='xs' isIndeterminate />
                <Text>Processing...</Text>
            </VStack>:
            <Button
                marginTop={'10px'}
                backgroundColor='#a080ff'
                width={'100%'}
                color={'white'}
                onClick={() => uploadFile (dataSourceName, toast, navigate, setIsProcessing)}

            > <HStack><MdSave/><Text>Save</Text></HStack> </Button>
          }
            
            
        </Box>


    </Flex>

}

const uploadFile = async (dataSourceName: string, toast: any, navigate: any, setIsProcessing: any) => {

    const formData = new FormData();
    const API_URL = process.env.REACT_APP_BACKEND_URL;

    formData.append(
      "file",
      file as Blob,
      file!.name
    );
    formData.append('data_source_name', dataSourceName);
    setIsProcessing(true)
    createFileDataSource(formData).then((result) => {
        toast({
            title: 'Created Successful',
            position: 'top',
            status: 'success'
        });

        navigate('/data-sources')
        
    })
    // const result = await axios.post(`${API_URL}/document-ai/chat/files`, formData);
    // const data: string = result.data.data as string
    // console.log((data.length) / 4)

  }