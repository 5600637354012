import axios from "axios"

const BACKEND = process.env['REACT_APP_BACKEND_URL'] as string

export const getAllDataSources  = async () => {
    
        return axios.get(BACKEND+'/data-sources/all')
}

export const getReportDataSources  = async (reportID: number) => {

    return axios.get(BACKEND+'/reports/'+reportID+'/data-sources')
}

export const createNewBlock = async (
    reportID: string,
    name: string,
    query: string,
    dataSourceID: string
    //viewType,
    // viewData.

) => {

    return axios.post(BACKEND+`/reports/${reportID}/blocks`, {
        title: name,
        query: query,
        data_source_id: dataSourceID
    })

}

export const bulkCreateBlocks = async (
    reportID: string,
    blocksData: any
    //viewType,
    // viewData.

) => {

    return axios.post(BACKEND+`/reports/${reportID}/blocks-bulk`, {
      blocks_data: blocksData
    })

}

export const createNewReport = async (title: string, dataSources: any) => {
    return axios.post(`${BACKEND}/reports`, {
        title: title,
        data_sources: dataSources
    })
}

export const createNewTemplate = async (title: string) => {
    return axios.post(`${BACKEND}/reports/templates`, {
        title: title
    })
}

export const getAllBlockResults = async (reportID: string) => {

    return axios.get(`${BACKEND}/reports/${reportID}/results`)

}

export const getBlockResult = async (reportID: string, blockID: string, isRedoing: boolean) => {

    return axios.get(`${BACKEND}/reports/${reportID}/blocks/${blockID}/result?is_redoing=${isRedoing}`)

}

export const updateBlockResult = async (reportID: string, blockID: string, newContent: string) => {

    return axios.patch(`${BACKEND}/reports/${reportID}/blocks/${blockID}/result`, {
        content: newContent
    })

}

export const getAllBlocks = async (reportID: string) => {

    return axios.get(`${BACKEND}/reports/${reportID}/blocks`)

}

export const getBlock = async (reportID: string, blockID: string) => {

    return axios.get(`${BACKEND}/reports/${reportID}/blocks/${blockID}`)

}
export const updateBlock = async (reportID: string, blockID: string, blockData: any) => {

    return axios.put(`${BACKEND}/reports/${reportID}/blocks/${blockID}`, blockData)

}

export const queryBlock = async (reportID: string, blockID: string, previousResponse: string, query: string) => {

    return axios.post(`${BACKEND}/reports/${reportID}/blocks/${blockID}/queries`, {
        previous_response:previousResponse, query
    })

}

export const createFileDataSource = async (
    formData: FormData
) => {
    return axios.post(`${BACKEND}/data-sources/files`, formData)
}