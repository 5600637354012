import { Box, Button, Card, CardBody, Flex, FormControl, FormLabel, HStack, Input, Menu, MenuButton, MenuItem, MenuList, ModalFooter, Spacer, Text, useToast, VStack } from "@chakra-ui/react";
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { MdAdd, MdHome, MdMenu, MdRestore, MdSource } from "react-icons/md"
import { useNavigate } from "react-router";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure
  } from '@chakra-ui/react'
import { createNewTemplate } from "../services/reports";

const BACKEND = process.env['REACT_APP_BACKEND_URL'] as string

export function TemplatesPage() {

    const [templates, setTemplates] = useState<Array<any>>([]);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const title = useRef<string>('');
    const toast = useToast();
    const navigate = useNavigate()


    useEffect(() => {

        async function loadTemplates() {
            const templates = await axios.get(BACKEND+"/reports/templates");
            setTemplates(templates.data.data['templates'])
        }

        loadTemplates();
    }, [])

    return <Flex direction={'row'}
        height={'100vh'}
        width={'100%'}
    >

        <SideBar/>
        <Flex direction={'column'}
            width={'70%'}
            height={'100vh'}
            paddingLeft={'10%'}

        >
           <HStack width={'500px'}>
           <Text
                fontWeight={150}
                fontSize={'50px'}
            >Templates</Text>
            <Spacer/>
            <Button
                backgroundColor='#a080ff'
                width={'200px'}
                color={'white'}
                onClick={onOpen}
                
            > <HStack><MdAdd/> <Text>New Template</Text></HStack> </Button>
           </HStack>
           {
                templates.map((template: any, key) => (
                    <TemplateCard template = {template} />
                ))
            }

            <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create New Template</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <FormControl>
                <FormLabel>
                    Title
                </FormLabel>
                <Input 
                onChange={(e) => {
                    title.current = e.target.value
                }}
                />
            </FormControl>
            </ModalBody>
  <ModalFooter>
                                <Button backgroundColor='#a080ff'

                                onClick={() => {
                                    createNewTemplate(title.current).then((result) => {
                                        toast({
                                            title: "Created Successfully",
                                            position: "top",
                                            duration: 1000,
                                            status: 'success'      
                                        });
                                        const id = result.data['data']['id'];
                                        navigate(`${id}/blocks/new`);
                                        
                                    }).catch(() => {
                                        toast({
                                            title: "Unable to create",
                                            position: "top",
                                            duration: 1000,
                                            status: 'error'      
                                        });
                                    })
                                }}
                                
                                color={'white'} mr={3}>
                                    Create
                                </Button>
                                
                            </ModalFooter>
          </ModalContent>
          
        </Modal>
            
            
        </Flex>


    </Flex>

}

function SideBar() {

    const navigate = useNavigate()

    return <Flex
        width={'30%'}
        height={'100vh'}
    >
        <VStack
       width = '100%'
       paddingLeft={'40%'}
       paddingTop={'60px'}
       
       >
            
             <Button
                backgroundColor='#a080ff'
                width={'200px'}
                color={'white'}
                onClick={() => navigate('data-sources')}
            > <HStack><MdSource/> <Text>Data Sources</Text></HStack> </Button>
            <Button
                backgroundColor='#a080ff'
                width={'200px'}
                color={'white'}
                onClick={() => null}
            > <HStack><MdRestore/> <Text>Templates</Text></HStack> </Button>
        </VStack>


    </Flex>
}

function TemplateCard(props: {template: any}) {

    const navigate = useNavigate()

    return <Card
        cursor={'pointer'}
        tabIndex={0}
        onClick={() => {
            navigate(''+props.template.id+'/blocks')
        }}
        marginTop={'10px'}
        width={{ base: '500px' }}
        height={'90px'}
        padding={'10px'}

    >

        <VStack alignItems={'left'} height={'100%'}>
            <HStack>
                <Text>
                   {props.template.title}
                </Text>
                <Spacer />
                {/* <Menu isLazy>
                    <MenuButton><MdMenu /></MenuButton>
                    <MenuList>
                     
                        <MenuItem>New Window</MenuItem>
                        <MenuItem>Open Closed Tab</MenuItem>
                        <MenuItem>Open File</MenuItem>
                    </MenuList>
                </Menu> */}
            </HStack>
            <Spacer />
            <HStack>
            <Text
                fontSize={'10px'}
            >Last Updated</Text>: <Text  fontSize={'9px'}>{new Date (props.template.updated).toLocaleDateString()}, {new Date (props.template.updated).toLocaleTimeString()}</Text>
            </HStack>
        </VStack>


    </Card>
}