import { Box, Card, HStack, IconButton, Text, Tooltip, VStack } from "@chakra-ui/react";
import { PDFIcon } from "./Icons";
import { MdCancel } from "react-icons/md";

export default function DataSourcesCard(props: {soureType: "pdf" | null, data: any}) {

    return <Card
    borderRadius={0}
    paddingY={'4px'}
    marginTop={'30px'}
    width={{base:'300px'}}
   

    >   
        <HStack alignItems={'center'} width={'100%'}>
        {props.soureType==="pdf"?<PDFIcon size='50px'/>: <></>}
        <VStack>
        <Text>{props.data.name}</Text>
        <HStack width={'100%'}>
        <Text
                fontSize={'10px'}
            >Updated</Text>: <Text  fontSize={'9px'}>{new Date (props.data.created).toLocaleDateString()}</Text>
        <Text
                fontSize={'10px'}
            >{new Date (props.data.updated).toLocaleTimeString()}</Text>
        </HStack>
        </VStack>
        </HStack>
    </Card>
}

export function DataSourceItem(props: {soureType: "pdf" | null, data: any}) {

    return <Box
    borderRadius={0}
    paddingY={'4px'}
    width={{base:'100%'}}
    cursor={'pointer'}
    onClick={() => null}
    _hover={{
        backgroundColor:'white'
    }}>   
    <Tooltip hasArrow label='' bg='gray.300' color='black'>
        <HStack alignItems={'center'} width={'100%'}>
        {props.soureType==="pdf"?<PDFIcon size='35px'/>: <></>}
        <VStack>
        <Text fontSize={'12px'}>{props.data.name}</Text>
        
            

       
        </VStack>
        </HStack>
        </Tooltip>
    </Box>
}

export function DataSourceGridItem(props: {soureType: "pdf" | null, data: any, setAddedDataSources: Function}) {

    return <Box
    borderRadius={0}
    paddingY={'4px'}
    width={{base:'100%'}}   

    >   
    <Tooltip hasArrow label='' bg='gray.300' color='black'>
        <HStack alignItems={'center'} width={'100%'}>
        {props.soureType==="pdf"?<PDFIcon size='35px'/>: <></>}
        <VStack>
        <Text fontSize={'12px'}>{props.data.name}</Text>

            

       
        </VStack>
        <IconButton aria-label="remove" backgroundColor={'transparent'} icon={<MdCancel/>}
        onClick={() => props.setAddedDataSources((prev: any) => {
            delete prev[props.data['id']]
            return {...prev}
        })}
        
        />
        </HStack>
        </Tooltip>
    </Box>
}