import { Box, Button, Card, Flex, HStack, Spacer, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { PDFIcon } from "../components/Icons";
import { MdAdd } from "react-icons/md";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import { useNavigate } from "react-router";
import DataSourcesCard from "../components/DataSourceCard";
import { useEffect, useState } from "react";
import { getAllDataSources } from "../services/reports";

//const BACKEND = process.env['REACT_APP_BACKEND_URL'] as string

export function DataSourcesPage() {

    const [dataSources, setDataSources] = useState<Array<any>>([]);

    useEffect(() => {
        getAllDataSources().then((response) => {
            setDataSources(response.data['data']['data_sources'])
        });
    }, [])

    const { isOpen, onOpen, onClose } = useDisclosure()
    return <Flex direction={'column'}
        alignItems={'center'}
        paddingTop={'50px'}
       
    >
        <HStack width={'400px'}><Text> Data Sources </Text>
        <Spacer/>
        <Button
                backgroundColor='#a080ff'
                width={'200px'}
                color={'white'}
                onClick={onOpen}
                
            > <HStack><MdAdd/> <Text>New Source</Text></HStack> </Button>
        </HStack>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Choose A Type</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Box width={"fit-content"} borderColor={'green'}
            borderWidth={'3px'}>
            <DataSourceTypesCard soureType={'pdf'} />
            </Box>
            </ModalBody>
  <Box height={'40px'}/>
          </ModalContent>
        </Modal>

        
       {
        dataSources.map((dataSource, index) => (
             
        <DataSourcesCard key={index} data = {dataSource} soureType={dataSource.type} />
       
        ))
       }
        
       

    </Flex>

}



function DataSourceTypesCard(props: {soureType: "pdf" | null}) {
    const navigate = useNavigate()
    return <Card 
    borderRadius={0}
    paddingY={'8px'}
    tabIndex={0}
    onClick={ () => navigate('/data-sources/new/'+props.soureType)}
    cursor={'pointer'}
    
    width={{base:'80px'}}
   

    >   
        <VStack alignItems={'center'} width={'100%'}>
        {props.soureType==="pdf"?<PDFIcon size='50px'/>: <></>}
        <Text textAlign={'center'}>PDF File</Text>

        </VStack>
    </Card>
}