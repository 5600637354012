import { Box, Button, Card, CardBody, Flex, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Skeleton, SkeletonText, Spacer, Text, VStack } from "@chakra-ui/react";
import axios from "axios"
import { useEffect, useState } from "react"
import { MdAdd, MdDownload, MdEdit, MdHome, MdMenu, MdSource } from "react-icons/md"
import { useNavigate, useParams } from "react-router";
import { PDFIcon } from "../components/Icons";
import { getAllBlockResults } from "../services/reports";

const BACKEND = process.env['REACT_APP_BACKEND_URL'] as string

export function PDFReport() {
    const {report_id} = useParams()

    const [results, setResults] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {

        getAllBlockResults(report_id as string).then((result) => {
            setResults(result.data['data']['results'])
            setIsLoading(false)
        })

        
    }, [])

    return <Flex direction={'row'}
        height={'100vh'}
        width={'100%'}
    >

        <Flex direction={'column'}
            alignItems={'center'}
            height={'100vh'}
            paddingLeft={'80px'}
            paddingRight={'80px'}
            paddingTop={'40px'}

        >
          
          <Text
                fontWeight={400}
                fontSize={'30px'}
            >Report</Text>
            
            
             
            { isLoading?<Box>
                <Box>
            <SkeletonText width={'80px'} mt='4' noOfLines={1} spacing='4' skeletonHeight='5' />
             <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='2' />
             <SkeletonText width={'80px'} mt='4' noOfLines={1} spacing='4' skeletonHeight='5' />
            </Box>
            <Box>
            <SkeletonText width={'150px'} mt='4' noOfLines={1} spacing='4' skeletonHeight='5' />
             <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='2' />
             <SkeletonText width={'80px'} mt='4' noOfLines={1} spacing='4' skeletonHeight='5' />
            </Box>
            </Box>:results.map((result: any, key) => (
                    <BlockCard data={result} key={key} />
                ))
            }
           



        </Flex>


    </Flex>

}



function BlockCard(props:{data: any}) {

    const navigate = useNavigate()

    return <Box
        marginTop={'10px'}
        width={{ base: '80%' }}
        
        padding={'10px'}
        id="noth"
    >
        <HStack>
        
        
        <Text
        fontWeight={'600'}
        fontStyle={'italic'}
        fontSize={'30px'}
        >{props.data.block.title}</Text>
            <Spacer />
           
           
        </HStack>
        <VStack
        marginTop={'10px'} 
        alignItems={'left'} height={'100%'}>
            <HStack>
                <Text
                    fontSize={'10px'}
                >Last Updated</Text>: <Text fontSize={'9px'}>{new Date(props.data.block.updated).toLocaleDateString()}, {new Date(props.data.block.updated).toLocaleTimeString()}</Text>
            </HStack>
            <Text whiteSpace={'pre-line'}> {props.data.result}
            </Text>
            
        </VStack>


    </Box>
}