import { Box, Button, Card, CardBody, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, HStack, IconButton, Input, Menu, MenuButton, MenuItem, MenuList, Skeleton, SkeletonText, Spacer, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { MdAdd, MdArticle, MdChat, MdDone, MdDownload, MdEdit, MdHistory, MdHome, MdMenu, MdRecycling, MdRedo, MdRefresh, MdSource } from "react-icons/md"
import { useNavigate, useParams } from "react-router";
import { PDFIcon } from "../components/Icons";
import { getAllBlockResults, getAllBlocks, getBlockResult, queryBlock, updateBlockResult } from "../services/reports";
import { useLocation } from "react-router-dom";
import { error } from "console";
import { on } from "events";


const BACKEND = process.env['REACT_APP_BACKEND_URL'] as string

export function Blocks() {
    const { report_id } = useParams()

    const [results, setResults] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const params = new URLSearchParams(useLocation().search)

    useEffect(() => {

        getAllBlocks(report_id as string).then((result) => {
            setResults(result.data['data'])
            setIsLoading(false)
        })


    }, [])

    return <Flex direction={'row'}
        height={'100vh'}
        width={'100%'}
    >

        <SideBar />
        <Flex direction={'column'}
            width={'80%'}
            height={'100vh'}
            paddingLeft={'80px'}
            paddingRight={'80px'}
            paddingTop={'40px'}

        >

            <HStack width={'80%'}>

                <Text
                    fontWeight={400}
                    fontSize={'30px'}
                >{params.get('title')}</Text>
                <Spacer />


                <PDFIcon size="20px" />
                <IconButton
                    backgroundColor={'transparent'}
                    onClick={() => {
                        window.location = `${BACKEND}/reports/${report_id}/pdf` as any
                    }}
                    aria-label="dowload" icon={<MdDownload />} />
            </HStack>



            {isLoading ? <Box>
                <Box>
                    <SkeletonText width={'80px'} mt='4' noOfLines={1} spacing='4' skeletonHeight='5' />
                    <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='2' />
                    <SkeletonText width={'80px'} mt='4' noOfLines={1} spacing='4' skeletonHeight='5' />
                </Box>
                <Box>
                    <SkeletonText width={'150px'} mt='4' noOfLines={1} spacing='4' skeletonHeight='5' />
                    <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='2' />
                    <SkeletonText width={'80px'} mt='4' noOfLines={1} spacing='4' skeletonHeight='5' />
                </Box>
            </Box> : results.map((result: any, key) => (
                <BlockCard data={result} key={"block_" + key} />
            ))
            }




        </Flex>


    </Flex>

}

function SideBar() {

    const navigate = useNavigate()

    return <Flex
        width={'20%'}
        height={'100vh'}
    >
        <VStack
            width='100%'
            paddingLeft={'40%'}
            paddingTop={'60px'}

        >

            <Button
                backgroundColor='#a080ff'
                width={'200px'}
                color={'white'}
                onClick={() => navigate('blocks/new/many')}
            > <HStack><MdAdd /> <Text>New Block </Text></HStack> </Button>
        </VStack>


    </Flex>
}

function BlockCard(props: { data: any }) { //notefib: to interface

    const toast = useToast();
    const navigate = useNavigate()
    const { report_id, block_id} = useParams();
    const [blockResultData, setBlockResultData] = useState<string | any | null>(null)
    const tableColumnsRef = useRef<Array<string>>([]);
    const chatInputRef = useRef<string>('');
    const [chatArray, setChatArray] = useState<Array<{type: "user" | "agent", content: string | any | null}>>([{type:"user", content:blockResultData}]);
    let refreshCounter = 0;
    const [isEditable, setIsEditable] = useState<boolean>(false);

    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isRedoing, setIsRedoing] = useState<boolean>(false);
    const [shouldBeginRedo, setShouldBeginRedo] = useState<boolean>(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen:isChatOpen, onOpen:onChatOpen, onClose: onChatClose } = useDisclosure()


    useEffect(() => {
        console.log('redoing')
        
        if (!props.data.last_result && !refreshCounter) {
            refreshCounter++
            console.log(isRedoing)
            getBlockResult(report_id as string, props.data.id, isRedoing).then((result) => {
                let resultContent = result.data['data']['result']['result']// notefib, this wrong
                setIsRedoing(false)
                //should also get timestamp


                if (props.data.result_format === "table") {
                    resultContent = JSON.parse(resultContent);
                    tableColumnsRef.current = Object.keys(resultContent[0]);

                }

                setBlockResultData(resultContent)
                
            })
        }

        if (props.data.last_result && props.data.result_format !== "table") {
            setBlockResultData(props.data.last_result)
        } else if (props.data.last_result && props.data.result_format === "table") {
            let resultContent = JSON.parse(props.data.last_result)
            setBlockResultData(resultContent)
            tableColumnsRef.current = Object.keys(resultContent[0]);
        }

       

    }, [shouldBeginRedo])

    return <Box
        marginTop={'10px'}
        width={{ base: '80%' }}

        padding={'10px'}
    >
        <HStack>


            <Text
                fontWeight={'600'}
                fontStyle={'italic'}
                fontSize={'30px'}
            >{props.data.title}</Text>
            <Spacer />
            {/* <IconButton 
            backgroundColor='#a080ff'
            color={'white'}
             aria-label="edit" icon={<MdEdit/>}/> */}

        </HStack>
        <VStack
            marginTop={'10px'}
            alignItems={'left'} height={'100%'}>
            <HStack>
                <Text


                    fontSize={'10px'}
                    autoFocus={true}
                >Last Updated</Text>: <Text fontSize={'9px'}>{new Date(props.data.last_result_created).toLocaleDateString()}, {new Date(props.data.created).toLocaleTimeString()}</Text>
                <Spacer />
                {
                    !isEditable ?
                        <IconButton icon={<MdArticle />}
                            onClick={() => setIsEditable(true)}

                            aria-label="edit" /> : <IconButton icon={<MdDone />}
                                isLoading={isUpdating}
                                onClick={() => {
                                    setIsUpdating(true)
                                    const content = document.getElementById('block_' + props.data.id)?.innerText
                                    updateBlockResult(report_id as string, props.data.id, content as string).then((result) => {
                                        setIsEditable(false)
                                        setIsUpdating(false)
                                        toast({
                                            title: "Updated Successfully",
                                            duration: 1000,
                                            status: 'success',
                                            position: 'top'
                                        })
                                    }).catch((error) => {
                                        setIsUpdating(false)
                                        toast({
                                            title: "User Error",
                                            duration: 2000,
                                            status: 'error',
                                            position: 'top'
                                        })
                                    })

                                }
                                }
                                aria-label="done" />
                }

                <IconButton
                    isLoading={isRedoing}
                    loadingText={"Rerunning"}
                    padding={'10px'}
                    isDisabled={true}
                    isActive={false}

                    icon={<MdRefresh />}

                    onClick={() => {
                        setBlockResultData(null)
                        setShouldBeginRedo(!shouldBeginRedo)
                        setIsRedoing(true)
                        props.data.last_result = null

                    }}

                    aria-label="rerun" as={Button} />



                <IconButton icon={<MdEdit />}
                    onClick={() => navigate("blocks/"+props.data.id+"/edit")}

                    aria-label="edit" />

                <IconButton icon={<MdChat/>}
                    onClick={() => 
                        
                        onChatOpen()}

                    aria-label="Chat" />    
                
                {/* <IconButton icon={<MdHistory />}
                    onClick={() => onOpen()}

                    aria-label="history" /> */}
            </HStack>

            {
                blockResultData ?
                    <> {props.data.result_format === "table" ?
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>

                                        {
                                            tableColumnsRef.current.map((column, key) => (
                                                <Th key={key}>{column}</Th>
                                            ))
                                        }
                                    </Tr>
                                </Thead>
                                <Tbody>

                                    {
                                        (blockResultData as Array<any>).map((row, key) => {
                                            return <Tr key={key}> {
                                                tableColumnsRef.current.map((column, subKey) => (
                                                    <Td key={subKey}>{row[column]}</Td>
                                                ))

                                            }

                                            </Tr>



                                        })
                                    }


                                </Tbody>

                            </Table>
                        </TableContainer> : <Text whiteSpace={'pre-line'}
                            borderWidth={isEditable ? '2px' : '0px'}
                            contentEditable={isEditable}
                            id={"block_" + props.data.id}
                        > {blockResultData}
                        </Text>} </> :
                    <Box>
                        <SkeletonText width={'80px'} mt='4' noOfLines={1} spacing='4' skeletonHeight='5' />
                        <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='2' />
                        <SkeletonText width={'80px'} mt='4' noOfLines={1} spacing='4' skeletonHeight='5' />
                    </Box>

            }




        </VStack>

        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={new Object() as any}
        >

            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Update History</DrawerHeader>

                <DrawerBody>
                    <p>dfsdf</p>
                </DrawerBody>


            </DrawerContent>
        </Drawer>

        <Drawer
            isOpen={isChatOpen}
            placement='right'
            onClose={onChatClose}
            finalFocusRef={new Object() as any}
            size={'lg'}
            
        >

            <DrawerContent >
                <Box ></Box>
                <DrawerCloseButton />
                <DrawerHeader></DrawerHeader>

                <DrawerBody overflow={'none'} paddingBottom={'30px'}>
                   <VStack justifyContent={'right'}
                   alignContent={'right'} justifyItems={'right'}
                    alignItems={'right'} overflowY={'auto'} height={'70%'} direction={'column'}>
                    
                    {
                        chatArray.map((data, key) => (
                            <ChatComponent type={data.type} content={data.content} key={key}/>
                        ))
                    }
                   
                   <Spacer/>
                    
                    
                   </VStack>
                   
                   <HStack 
                   backgroundColor={'white'}
                   width={'90%'} padding={'0px'} zIndex={'4000'} position={'fixed'} bottom={'0px'} height={'70px'}>
                    <Input id={"chat_input_" + props.data.id}
                    
                    onChange={(e) => {
                        chatInputRef.current = e.target.value;
                    }}
                
                    />
                    <Button

                    onClick={() => {
                        setChatArray([...chatArray, {type: "user", content: chatInputRef.current}])
                        let inputElement = document.getElementById("chat_input_" + props.data.id) as HTMLInputElement
                        inputElement.value = ''
                        queryBlock(report_id as string, props.data.id as string, blockResultData, chatInputRef.current).then((result) => {
                            
                            setChatArray([...chatArray, {type: "user", content: chatInputRef.current}, {type: "agent", content: result.data['data']['content']}])
    
                        }).catch((error) => {
                            console.log(error)
                            toast({
                                title: "Unable to complete request",
                                duration: 2000,
                                status: 'error',
                                position: 'top'
                            })
                        })
                    }}
                    
                    >Send</Button></HStack>
                </DrawerBody>


            </DrawerContent>
        </Drawer>

    </Box>
}

function ChatComponent (props: {type: string, content: string}) {


    if (props.type === "agent")
        return <>
              <Box backgroundColor={''}
                   boxShadow={'md'}
                   borderRadius={'lg'}
                   padding={'20px'}
                   >
                   <Text whiteSpace={'pre-line'}
                            fontSize={'15px'}
                        > {props.content}
                        </Text>
                   </Box>
        </>

    else
        return <>
        <Text whiteSpace={'pre-line'}
                            fontSize={'15px'}
                        > {props.content}
                        </Text>
        </>

}